/* eslint-disable @next/next/no-img-element */
import PageError from '@/components/shared/errors/page-error';
import FullScreenLoader from '@/components/shared/loaders/full-screen-loader';
import { useAuth0 } from '@auth0/auth0-react';
import { Anchor, Box, Button, Flex, Image, Text, Title } from '@mantine/core';
import Head from 'next/head';
import { useRouter } from 'next/router';

// Default index page should either prompt logged-out users to login, or redirect logged-in users to /inbox
export default function Home() {
    const { isLoading, isAuthenticated, error, loginWithRedirect } = useAuth0();
    const router = useRouter();

    if (isLoading) return <FullScreenLoader />;
    if (error) return <PageError error={error} message={error.message} />;

    // User is logged in! Redirect to the auth callback for next steps
    if (isAuthenticated) {
        router.push('/auth-callback');

        return null;
    }

    // User is logged out - prompt them to login
    return (
        <>
            <Head>
                <title>dvmGateway Portal</title>
                <meta property="og:title" content="dvmGateway Portal" key="title" />

                {/* TODO: dvmReach favicon for now, maybe change in the future */}
                <link rel="icon" type="image/x-icon" href="/favicon-reach.ico" />
            </Head>

            <main>
                <Flex
                    direction="column"
                    align="center"
                    justify="center"
                    w="100vw"
                    h="100vh"
                    gap="md"
                >
                    {/* Logo */}
                    <Image src="/gateway-logo.png" alt="dvmGateway logo" w="12rem" />

                    {/* Header */}
                    <Title order={2} mt="xs">
                        Welcome to dvmGateway!
                    </Title>

                    {/* Login button */}
                    <Button onClick={() => loginWithRedirect()} color="black">
                        Click here to login
                    </Button>

                    {/* Contact us if no account yet */}
                    <Box style={{ textAlign: 'center' }}>
                        <Text>Not a member yet?</Text>

                        <Text>
                            Learn more about{' '}
                            <Anchor
                                href="https://dvmreach.com"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="reach-link"
                            >
                                dvmReach
                            </Anchor>{' '}
                            and{' '}
                            <Anchor
                                href="https://dvmrx.com"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="rx-link"
                            >
                                dvmRx
                            </Anchor>
                        </Text>
                    </Box>
                </Flex>
            </main>
        </>
    );
}
